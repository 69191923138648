import * as React from 'react'
import { LinkProps, useNavigate } from 'react-router-dom'
import { Button, ButtonProps } from './Button'
import { hasValue } from '@digital-magic/ts-common-utils'

export type RouterButtonProps = Readonly<{
  to?: LinkProps['to'] & { state?: unknown }
}> &
  ButtonProps

export const RouterButton: React.FC<RouterButtonProps> = ({ to, onClick, ...props }) => {
  const navigate = useNavigate()

  const onClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      if (hasValue(to)) {
        if (typeof to === 'string') {
          navigate(to)
        } else {
          const { state, ...restTo } = to

          navigate(restTo, { state })
        }
      }
      if (hasValue(onClick)) {
        onClick(e)
      }
    },
    [to, navigate, onClick]
  )

  return <Button onClick={onClickHandler} {...props} />
}
